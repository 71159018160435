var lang = require('/resources/lang/hu.json');

$(function () {
    "use strict"; // Start of use strict


});

function showCookieBanner() {
    $(".cookie-info").show();
}

function hideCookieBanner() {
    $(".cookie-info").hide();
}

$(document).ready(function () {

    let consentCookie = getCookie("gloster_cookie_consent");

    if (!consentCookie) {
        $('.cookie-info').removeClass('d-none');
    }

    $(".cookie-decline").on("click", function (event) {
        event.preventDefault();
        setCookie("gloster_cookie_consent", "false", 3650);
        closeCookieBanner();
    });

    $(".cookie-accept").on("click", function (event) {
        event.preventDefault();
        setCookie("gloster_cookie_consent", "true", 3650);
        closeCookieBanner();
    });

    function closeCookieBanner() {
        $(".cookie-info").slideUp({
            complete: function () {
                hideCookieBanner();
            }
        });
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function setCookie(cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
});


particlesJS("particles-js", {
    "particles": {
        "number": {
            "value": 50,
            "density": {
                "enable": true,
                "value_area": 700
            }
        },
        "color": {
            "value": "#f2f2f2"
        },
        "shape": {
            "type": "circle",
            "stroke": {
                "width": 0,
                "color": "#000000"
            },
            "polygon": {
                "nb_sides": 5
            },
        },
        "opacity": {
            "value": 1,
            "random": false,
            "anim": {
                "enable": false,
                "speed": 0.1,
                "opacity_min": 0.1,
                "sync": false
            }
        },
        "size": {
            "value": 10,
            "random": true,
            "anim": {
                "enable": false,
                "speed": 10,
                "size_min": 0.1,
                "sync": false
            }
        },
        "line_linked": {
            "enable": true,
            "distance": 150,
            "color": "#f2f2f2",
            "opacity": 1,
            "width": 2
        },
        "move": {
            "enable": true,
            "speed": 1,
            "direction": "none",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
                "enable": false,
                "rotateX": 600,
                "rotateY": 1200
            }
        }
    },
    "retina_detect": true
});
